<template>
  <div class="page">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input v-model="tableParams.kw" placeholder="请输入昵称 / 手机号搜索" @input="kwChange" clearable class="mr15" />
        <el-select v-model="tableParams.levelConfigId" placeholder="会员等级" @change="reload" clearable>
          <el-option v-for="item in vipLevel" :key="item.levelConfigId" :label="item.levelName"
            :value="item.levelConfigId">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onExport()">导出</el-button>
      </div>
    </section>
    <div style="width: 50%;display: flex;margin-bottom: 20px;">
  
    <div class="desc_item">总用户数：{{ headInfo.total }}</div>
    <div class="desc_item">今日新增用户：{{ headInfo.today }}</div>
    <div class="desc_item">7天新增用户：{{ headInfo.last7Days }}</div>
    <div class="desc_item">30天新增用户：{{ headInfo.last30Days}} </div>
    </div>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="用户" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.nickName || "-" }}</span>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ scope.row.userMobile || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户id" align="center" prop="memberId"> </el-table-column>
      <el-table-column label="首次访问时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.addDt || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最近访问时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.updDt || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最近下单时间" align="center" prop='createOrderTime'>
      </el-table-column>
      <el-table-column label="累计完成订单时长" align="center" prop="total">
      </el-table-column>
      <el-table-column label="成为会员时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.memberLevelVo.firstLevelTime?scope.row.memberLevelVo.firstLevelTime:"-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="会员等级" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.memberLevelVo.levelConfigVo?scope.row.memberLevelVo.levelConfigVo.levelName:"-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="历史累计充值（本金）" align="center">
        <template  slot-scope="scope">
         <span>{{ '￥'+scope.row.historyTotalRecharge || ''}}</span>
        </template>
      </el-table-column>
      <el-table-column label="累计充值（本金）" align="center" >
        <template  slot-scope="scope">
         <span>{{ '￥'+scope.row.totalRecharge || ''}}</span>
        </template>
      </el-table-column>
      <el-table-column label="成长值" align="center" prop="memberLevelVo.experience">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onInfo(scope.row)">详情</el-button>
          <GiveCard :row="scope.row"></GiveCard>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
  </div>
</template>

<script>
import {
  getUserList,
  getUserListExport,
  getPUserCount,
  getLevelConfig
} from "@/api/user/user";
import { getUserlCube } from "@/api/home/home";
import { ObjToOpt } from "@/utils/utils";
import GiveCard from "./child/GiveCard.vue"
export default {
  components: {
    GiveCard
  },
  data() {
    return {
      ObjToOpt,
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
      },
      headInfo:{},
      vipLevel:[],
    }
  },
  created() {
  
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
    this.getUserlCube();
  },
  mounted() {
    this.getUserList();// 【请求】表格数据
    this.getLevelList()
  },
  methods: {
     // 获取头部数据
      // 【请求】用户统计豆腐块
    getUserlCube() {
      let data = this.tableParams;
      getUserlCube(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
          

            this.headInfo = res.data
          }
        }
      });
    },
     getPUserCount() {
      getPUserCount().then(res => {
       this.headInfo=res.data
      })
    },
    // 获取等级列表
    getLevelList() {

      getLevelConfig({ enable: true }).then((res) => {
        if (res.isSuccess == "yes") {
          this.vipLevel = res.data;
        }
      });
    },
    // 【请求】表格数据
    getUserList() {
      let data = this.tableParams;
      getUserList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getUserList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getUserList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】导出
    onExport() {
      let data = {
        merchantId: this.tableParams.merchantId,//商户ID
        shopId: this.tableParams.shopId,//商户ID
      }
      getUserListExport(data)
    },

    // 【监听】用户详情
    onInfo(row) {
      this.$router.push({
        path: "/user/userInfo/baseInfo",
        query: {
          userId: row.userId,
          shopId: row.shopId,
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: .15rem;
}
.desc_item{
  margin-right: 20px;
  font-weight: 500;
}
.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}
</style>
import request from "@/utils/request";
// 房源列表
export function getRoomList(data) {
  return request("post", "/apm/room/roomList", data);
}

// 服务列表列表
export function getServiceList(data) {
  return request("post", "/apm/service/findAll", data);
}

// 查询房间详情
export function getRoomInfo(data) {
  return request("post", "/apm/room/readInfo", data);
}

// 新增修改房源
export function getRoomEdit(data) {
  return request("post", "/apm/room/update", data);
}

// 高级定价列表
export function getRoomPriceList(data) {
  return request("post", "/apm/room/priceList", data);
}

// 高级定价新增
export function getRoomPriceAdd(data) {
  return request("post", "/apm/room/priceCreate", data);
}
// 服务位置排序
export function getTableSort(data) {
  return request("post", "/apm/room/top", data);
}

// 高级定价编辑
export function getRoomPriceEdit(data) {
  return request("post", "/apm/room/priceUpdate", data);
}

// 高级定价删除
export function getRoomPriceDel(data) {
  return request("post", "/apm/room/priceDelete", data);
}

// 处理位置码
export function getMiniDeal(data) {
  return request(
    "get",
    "/apm/mini/deal?shopId=" +
      data.shopId +
      "&serviceTemplateType=" +
      data.serviceTemplateType
  );
}

// 获取处理进度
export function getMiniProgress(data) {
  return request(
    "get",
    "/apm/mini/progress?shopId=" +
      data.shopId +
      "&serviceTemplateType=" +
      data.serviceTemplateType
  );
}

// 获取下载链接
export function getDownloadUrl(data) {
  return request(
    "get",
    "/apm/mini/download?shopId=" +
      data.shopId +
      "&serviceTemplateType=" +
      data.serviceTemplateType
  );
}

<template>
  <div class="give-card">
    <el-button type="text" size="medium" @click.stop="onGiveCard">发放卡券</el-button>
    <!-- 编辑弹框 -->
    <el-dialog title="发放卡券" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="50%">
      <!-- 筛选 -->
      <section class="table-search" style="padding-bottom: .15rem;border-bottom: 1px solid #eee;">
        <div class="search-L">
          <el-input v-model="formCardParams.shopName" autocomplete="off" maxlength="20" disabled class="mr15"></el-input>
          <el-select v-model="formCardParams.serviceId" placeholder="请选择服务内容" @change="getCardList" clearable
            class="mr15">
            <el-option v-for="item in serviceList" :key="item.serviceId" :label="item.serviceName"
              :value="item.serviceId">
            </el-option>
          </el-select>
          <el-select v-model="formCardParams.cardType" placeholder="请选择卡券类型" @change="getCardList" clearable class="mr15">
            <el-option v-for="item in ObjToOpt(cardTypeObj)" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </section>
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px" v-if="cardList.length">
        <section class="form-main">
          <el-form-item label="卡券列表" prop="couponId" class="long-content">
            <el-radio-group v-model="params.couponId">
              <div class="shop-item" :span="6" v-for="item in cardList" :key="item.cardId">
                <el-radio-button border :label="item.cardId">{{ item.cardName }}</el-radio-button>
              </div>
            </el-radio-group>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">确认发放</el-button>
        </section>
      </el-form>
      <el-empty v-else description="暂无卡券，请先添加卡券"></el-empty>
    </el-dialog>
  </div>
</template>
<script>
import {
  getGiveCard
} from "@/api/user/user";
import {
  getCardList,
} from "@/api/group/card";
import { cardTypeObj } from "@/db/objs";
import {
  getServiceList,
} from "@/api/resource/room";
import { rules } from "@/db/rules";
import { ObjToOpt } from "@/utils/utils";
export default {
  name:"GiveCard",
  props: ["row"],
  data() {
    return {
      cardTypeObj,
      rules,
      ObjToOpt,
      // 弹框
      editShow: false, //弹框开关
      shopList: [],
      cardList: [],
      serviceList: [],
      formCardParams: {
        merchantId: "", //商户ID
        shopId: "", //门店ID
        shopName: "", //门店ID
        serviceId: "",
        cardType: "",
      },
      params: {
        couponId: "",
      }
    }
  },
  methods: {
    // 【请求】服务列表
    getServiceList() {
      let data = {
        shopId: this.formCardParams.shopId
      };
      getServiceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.serviceList = res.data;
        }
      });
    },

    // 【请求】查询修改绑定卡券列表
    getCardList() {
      let data = this.formCardParams;
      getCardList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.cardList = res.data;
        }
      });
    },

    // 【请求】修改发放卡券
    getGiveCard() {
      let data = {
        shopId: this.formCardParams.shopId,
        userId: this.row.userId,
        shopUserId: this.row.shopUserId,
        goodsId: this.params.couponId,
      };
      getGiveCard(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
        }
      });
    },

    // 【监听】发放卡券
    onGiveCard() {
      this.editShow = true;
      let merchantShopInfo = JSON.parse(window.localStorage.getItem('playOne-store-merchantShopInfo'));
      if (merchantShopInfo) {
        this.formCardParams.merchantId = merchantShopInfo.merchantId;
        this.formCardParams.shopId = merchantShopInfo.shopId;
        this.formCardParams.shopName = merchantShopInfo.shopName;
      }
      this.params.couponId = "";
      this.getServiceList();
      this.getCardList();
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getGiveCard();
        } else {
          return false;
        }
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.give-card {
  display: inline-flex;
  margin: 0 .1rem;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.el-radio-group {
  width: 100%;
}

.shop-item {
  display: inline-flex;
  margin-right: .1rem;
  margin-bottom: .1rem;
}
</style>